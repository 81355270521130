import React from "react"
import styled from "styled-components"
import { style } from "../utils/Index"

const Wrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const MyStyledButton = styled.a`
  color: white;
  margin: 0 auto;
  cursor: pointer;
  outline-style: none;
  display: block;
  height: auto;
  width: max-content;
  color: white;
  padding: 1rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  transition: 0.3s;
  background-color: ${style.kolory.tayamaZielony};
  :hover {
    padding-left: 2rem;
    padding-right: 2rem;
    transition: 0.3s;
    border-radius: 2rem;
    vertical-align: middle;
    color: white;
  }
`
const Page404 = () => (
  <Wrapper>
    <div>
      <h1>Oops! błędna strona</h1>
      <MyStyledButton href='/'>Powrót do strony głównej</MyStyledButton>
    </div>
  </Wrapper>
)

export default Page404
